import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

import { UserAuthFactory, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { CompanyService } from 'src/app/common-header/services/company.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import { SafeDeleteModalComponent } from '../safe-delete-modal/safe-delete-modal.component';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';

@Component({
  selector: 'company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  @Input() companyId;

  company: any = {};
  loading = false;
  formError = null;
  apiError = null;
  isAddressError = false;
  isRiseStoreAdmin = this.userStateService.isRiseStoreAdmin();

  @ViewChild('companyForm')
  public companyForm: NgForm;

  constructor(private _location: Location,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private userAuthFactory: UserAuthFactory,
    public companyService: CompanyService,
    private companyApiService: CompanyApiService,
    private addressFactory: AddressService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode,
    public currentPlanService: CurrentPlanService,
    private modalService: ModalService,
    private apiUtils: ApiUtilsService
  ) { }

  ngOnInit() {
    if (this.companyId) {
      this.loading = true;
      this.companyApiService.getCompany(this.companyId)
        .then((company) => {
          this.company = company;
          this.company.isChargebee = company && company.origin === 'Chargebee';
        })
        .catch((resp) => {
          this._showErrorMessage('load', resp);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  save() {
    this._clearErrorMessages();

    if (!this.companyForm.valid) {
      console.log('Form not valid');
    } else {
      this.loading = true;

      this.addressFactory.validateAddressIfChanged(this.companyForm.form.controls, this.company)
        .then(() => {
          var company = _.cloneDeep(this.company);

          this._verifyAdmin(company);
          return this.companyApiService.updateCompany(this.company.id, company)
            .then(() => {
              this.tracker.companyEvent('Company Updated', this.companyStateService.getSelectedCompanyId(),
                this.companyStateService.getSelectedCompanyName(), !this.companyStateService.isSubcompanySelected());

              this.companyStateService.updateCompanySettings(this.company);
            })
            .catch((error) => {
              this._showErrorMessage('update', error);
            });

        })
        .catch((error) => {
          this.formError = 'We couldn\'t update your address.';
          this.apiError = this.processErrorCode(error);
          this.isAddressError = true;
        })
        .finally(() => {
          this.loading = false;
        });

    }
  }

  deleteCompany() {
    this._clearErrorMessages();

    this.modalService.showMediumModal(SafeDeleteModalComponent, {
      initialState: {
        name: this.company.name
      }
    }).then(() => {
      this.loading = true;
      this.companyApiService.deleteCompany(this.company.id)
        .then(() => {
          this.tracker.companyEvent('Company Deleted', this.companyStateService.getSelectedCompanyId(),
            this.companyStateService.getSelectedCompanyName(), !this.companyStateService.isSubcompanySelected());

          if (this.companyStateService.getUserCompanyId() === this.company.id) {
            this.userAuthFactory.signOut();
          } else if (this.companyStateService.getSelectedCompanyId() === this.company.id) {
            this.companyStateService.resetCompany();
          }
        })
        .catch((error) => {
          if (this.apiUtils.getError(error).message == 'Company has active subscriptions and cannot be deleted.') {
            const message1 = 'You have an active subscription and cannot delete your company.';
            const message2 = 'Please contact <a href="mailto:help@risevision.com">help@risevision.com</a> for assistance.';

            this.modalService.showMessage('You have an active subscription.', `${message1} ${message2}`);
          } else {
            this._showErrorMessage('delete', error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }

  exportCompany() {
    this.companyService.exportCompany(this.company)
      .catch((error) => {
        if (error) {
          this._showErrorMessage('export', error);
        }
      });
  }

  _resetCompanyField(type, title, message) {
    this._clearErrorMessages();

    return this.modalService.confirm(title, message)
      .then(() => {
        this.loading = true;

        return this.companyApiService.regenerateField(this.company.id, type)
          .catch((error) => {
            this._showErrorMessage('update', error);
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }

  resetAuthKey() {
    var type = 'authKey';
    var title = 'Reset Authentication Key',
      message = 'Resetting the Company Authentication Key will cause existing Data Gadgets ' +
      'to no longer report data until they are updated with the new Key.';

    this._resetCompanyField(type, title, message)
      .then((resp) => {
        this.company.authKey = resp.item;
      });
  }

  resetClaimId() {
    var type = 'claimId';
    var title = 'Reset Claim Id',
      message = 'Resetting the Company Claim Id will cause existing installations to no ' +
      'longer be associated with your Company.';

    this._resetCompanyField(type, title, message)
      .then((resp) => {
        this.company.claimId = resp.item;
      });
  }

  _verifyAdmin(company) {
    if (!this.isRiseStoreAdmin) {
      //exclude fields from API call
      delete company.shareCompanyPlan;
      delete company.unlockAllFeatures;
    }
  }

  _clearErrorMessages() {
    this.formError = null;
    this.apiError = null;
    this.isAddressError = false;
  }

  _showErrorMessage(action, error) {
    this.formError = 'Failed to ' + action + ' Company.';
    this.apiError = this.processErrorCode(error);
  }

  cancel() {
    this._location.back();
  }

}
